<template>
   <div class="apply">
       <div class="toptit">
           <p><span>企业认证</span>进行企业认证后，您在本平台进行投保或批改时，投保或批改资料无需再进行线下盖章。</p>
       </div>
       <div class="message">
           <div class="flow">
               <div>
                   <img src="../../image/signet/flow1.png" alt="">
                   <p>企业信息认证</p>
               </div>
               <span class="activate"></span>
               <div>
                    <img src="../../image/signet/flow2-1.png" alt="">
                    <p>申请电子签章</p>
               </div>
               <span></span>
               <div>
                    <img src="../../image/signet/flow3.png" alt="">
                    <p>生成电子签章</p>
               </div>
           </div>
           <div class="imgbox">
               <img src="../../image/signet/apply.png" alt="">
               <p>申请电子签章</p>
           </div>
           <div class="textbox">
               <p>尊敬的客户{{name}}您好，为方便您后续进行<span>投保、续投、理赔</span>等操作，<br>我们将使用已认成功的企业信息为企业生成并使用电子签章！</p>
           </div>
           <div class="checkbox">
                <el-checkbox v-model="checked" class="chec">我已阅读并同意 </el-checkbox><a href="/file/Digital_certificate_usage_protocol.pdf" target="_blank">《数字证书使用协议》、</a><a href="/file/Privacy_Policy.pdf" target="_blank">《隐私权政策》</a>、<a href="/file/Electronic_signature_authorization.pdf" target="_blank">《电子签名授权书》</a>
           </div>
           <div class="btnbox">
               <el-button type="primary" @click="apply()">申请电子签章</el-button>
           </div>
       </div>       
   </div>
</template>
<script>    
    export default {
        name: "apply",
       
        data() {
            return {
                baseUrl:this.$store.state.baseUrl,
                uid:'',
                name:'',// 公司名称
                checked:false,//是否勾选同意
                regCode:'',
                data:''//请求的数据
            }
        },
        mounted() {
            this.uid = this.$route.query.id
            this.name = this.$route.query.name
            this.regCode = this.$route.query.regCode
            var that=this
            that.axios({
                url: this.baseUrl + '/api/authentication/authentication/getCertificationInfo',
                method: 'POST',
                params:{
                   uid:that.uid,
                   regCode:that.regCode,
                },
            }).then(function(res) {
                console.log(res)
                if(res.data.code==200){
                    that.data=res.data.data
                }else{
                    that.$message.error(res.data.msg);
                }
            }).catch(function (error) {               
                console.log(error);
            });
        },
        methods: {
            // 点击申请
            apply:function(){
                var that=this
                if(that.checked){
                    that.$alert('<div class="imgbox"></div><p>电子签章申请中，请您耐心等待~</p>', '', {
                        dangerouslyUseHTMLString: true,
                        center: true,
                        customClass:"hint",
                        confirmButtonText:"好的",
                        closeOnClickModal:true
                    })
                    that.axios({
                        url: this.baseUrl + '/api/authentication/authentication/enterpriseCertification',
                        method: 'POST',
                        params:that.data,
                    }).then(function(res) {
                        console.log(res)
                       let a = document.body.getElementsByClassName("v-modal")[0];
                        a.click();
                        if(res.data.code==200){
                            that.$router.push({
                                path:'create',
                                query:{
                                    // id:that.uid,
                                    // name:that.name
                                }
                            })
                        }else{
                            that.failure()
                        }
                    }).catch(function (error) {
                        that.failure()
                        console.log(error);
                    });
                }else{
                    that.$message.warning('请阅读协议并确认')
                }
            },
            // 申请失败
            failure() {
                var that=this
                that.$alert('<div class="imgbox fail"></div><p>很抱歉，网络异常，请您重新申请电子签章。</p>', '', {
                    dangerouslyUseHTMLString: true,
                    center: true,
                    customClass:"hint",
                    confirmButtonText:"好的"
                });
            }

        }
    }
</script>
<style lang="scss">
    .chec{
        .el-checkbox__input.is-checked+.el-checkbox__label{
            color:#4A76EF
        }
        .el-checkbox__input.is-checked .el-checkbox__inner{
            background: #678DF7;
        }
    }
    .hint{
        border:0;
        .el-message-box__header{            
            background: #5A86E0;
            .el-icon-close{
                color:#fff;
            }
        }
        .imgbox{
            width: 96px;
            height:96px;
            background:url(../../image/signet/await.png) no-repeat center center;
            background-size: 100% 100%;
            margin: 30px auto 15px;
        }
        .imgbox.fail{
            background:url(../../image/signet/wifi.png) no-repeat center center;
        }
        .imgbox+p{
            font-size: 14px;
            font-weight: 400;
            color: #333333;
        }
        .el-button{            
            background: #678DF7;
        }
    }
</style>
<style scoped lang="scss">
    .apply{
        background: #fff;
        text-align: left;        
        font-size: 12px;        
        font-weight: 400;
        max-width: 1000px;
        margin:0 auto;
        .toptit{
            color: #666666;
            padding:25px 15px;
            border-bottom: 1px solid rgb(238, 240, 242);
            p{
                display: flex;
                align-items: center;
                justify-content:flex-start;
                margin: 0;
            }
            span{
                font-size: 14px;
                font-weight: bold;
                color: #333333; 
                display: inline-block;
                margin: 0 10px;
                min-width: 60px;
            }
            p::before{
                content: "";
                width: 3px;
                height: 18px;
                background: #678DF7;
                border-radius: 2px;
                display: inline-block;               
            }
            
        }
        .message{
            padding:30px;            
            .flow{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 50px;
                div{
                    img{
                        width:65px;
                    }
                    p{
                        color: #333333;
                        margin: 3px 0 0;
                    }
                }
                span{
                    flex:1;                    
                    height: 6px;
                    background: #C6C6C6;
                    border-radius: 3px;
                    margin-bottom: 18px;
                }
                span.activate{
                    background: #678DF7;
                }
            }
            .imgbox{
                margin:70px auto 30px;
                text-align: center;
                p{                   
                    font-size: 14px;
                    font-weight: bold;
                    color: #586370; 
                    margin:20px 0 0;
                }
            }
            .textbox{
                max-width: 600px;
                font-size: 14px;
                font-weight: 400;
                color: #666666;
                text-align: center;
                margin:0 auto;
                line-height: 1.7;
                p{
                    margin: 0;
                }
                span{
                    color:#678DF7;
                }
            }
            .checkbox{
                text-align: center;
                margin:50px auto 70px;
                a{
                    text-decoration: none;
                    color:#4A76EF;
                    white-space: nowrap;
                }
            }
            .btnbox{
                margin:0 auto 20px;
                text-align: center;
                button{
                    background:#4A76EF
                }
            }

        }
    }
    @media screen and (max-width:575px){
        .apply .message .imgbox{
            margin:30px auto 20px;
            img{
                max-width: 150px;
            }
            p{
                margin: 10px 0 0;
            }
        }
        .apply .message .checkbox{
            margin: 15px auto 20px;
        }
        .apply .message .btnbox{
            margin: 0 auto;
        }
        
    }
</style>